import { getUser } from "~/domains/auth/helpers";

export default defineNuxtRouteMiddleware(async (to) => {
  const user = await getUser();
  if (!user) {
    return navigateTo(
      {
        path: "/login",
        query: { return_to: to.fullPath },
      },
      { replace: true, redirectCode: 302 },
    );
  }
});
